import React from 'react'
import "./App.scss"

function App () {
  return (
    <>
      <h1 className="display-3">Where do you want to go today?</h1>
      <div className="cards">
      <div className="card">
          <div className="card-body">
            <h5 className="card-title">smart.hyte.pl</h5>
            <h6 className="card-subtitle mb-2 text-muted">Automatyka Domowa Loxone</h6>
            <p className="card-text">
              Certyfikowani instalatorzy, programiści i automatycy. Inteligentny dom, który działa, nie przeszkadza, jest nowoczesny i otwarty na integrację z innymi systemami.
            </p>
            <a href="https://smart.hyte.pl" className="card-link">Odwiedź smart.hyte.com</a>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Medium.com</h5>
            <h6 className="card-subtitle mb-2 text-muted">Mój blog</h6>
            <p className="card-text">
              O programowanieu, DevOpsach, budowie foodtrucka. W większości po angielsku, ale jest też kilka polskich tekstów.
            </p>
            <a href="https://medium.com/@jaaaco" className="card-link">Odwiedź Medium.com</a>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">IT</h5>
            <h6 className="card-subtitle mb-2 text-muted">Docker, Meteor, NodeJS, DevOps</h6>
            <p className="card-text">Narzędzia ułatwiające nowoczesną administrację serwerami i kilka projektów Open Source.</p>
            <a href="https://github.com/jaaaco" className="card-link">Odwiedź profil na github.com</a>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Siedlisko Góra Wiatrów</h5>
            <h6 className="card-subtitle mb-2 text-muted">Permakultura | natura | ekologia</h6>
            <p className="card-text"> Skoro się tutaj znalazłeś można wnioskować, że jesteś ekoludkiem i prawdopodobnie szukasz informacji o permakulturze.</p>
            <a href="https://siedlisko.wietrzyk.com" className="card-link">Odwiedź siedlisko.wietrzyk.com</a>
          </div>
        </div>
      </div>
    </>

    //   <li><a href="">3D</a></li>
    //   <li><a href=""></a></li>
    //   <li><a href="https://techsource.pro">Software Developement</a></li>
    // </ul>
  )
}

export default App
